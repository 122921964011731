import React, { useRef, useEffect, useState } from 'react';
import { useForm } from 'react-hubspot';

import close from '@/images/WebSummit/close.svg';

import * as general from '@/components/general/FormPages.styled';

import { Feature, Form, BlurBox } from '@/components/general';
import * as styled from './Content.styled';

interface IContent {
  isForm?: boolean;
  setState?: React.Dispatch<React.SetStateAction<number>>;
  data?: any;
}

export const Content: React.FC<IContent> = ({
  isForm = true,
  setState,
  data,
}) => {
  const formData = useForm({
    portalId: process.env.GATSBY_HUBSPOT_PORTAL_ID,
    formId: process.env.GATSBY_HUBSPOT_INVESTER_FORM_ID,
  });

  const [videoFaqBoxHeight, setVideoFaqBoxHeight] = useState<number>(0);

  const VideoFaqBoxRef = useRef(null);

  useEffect(() => {
    if (VideoFaqBoxRef && VideoFaqBoxRef.current) {
      setVideoFaqBoxHeight(VideoFaqBoxRef.current.offsetHeight);
    }
  }, [VideoFaqBoxRef?.current?.offsetHeight]);

  return (
    <styled.Box isForm={isForm}>
      <BlurBox $hasWrapper>
        <styled.Header>
          <general.Title>We make product. Not noise.</general.Title>
          <general.UnderTitle>
            Let&apos;s respect everyone&apos;s time. We&apos;ve prepared the
            Product Demo, FAQ, and Data Room for you. Feel free to talk with us
            to get access!
          </general.UnderTitle>
        </styled.Header>
        <styled.MainContent>
          <styled.VideoFaqBox ref={VideoFaqBoxRef}>
            <styled.VideoBox>
              <styled.VideoTitle>Five minutes product demo</styled.VideoTitle>
              <styled.Iframe
                allowfullscreen="allowfullscreen"
                src="https://www.youtube.com/embed/ZJfYLT7LAio"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
              />
            </styled.VideoBox>
            <styled.FaqBox>
              <styled.FaqTitle>FAQ / Data Room</styled.FaqTitle>
              <styled.FaqList>
                {data.faq.map((item) => (
                  <Feature
                    key={item.id}
                    question={item.question}
                    answer={item.answer.answer}
                  />
                ))}
              </styled.FaqList>
            </styled.FaqBox>
          </styled.VideoFaqBox>
          <styled.FormContainer $maxHeight={videoFaqBoxHeight}>
            <styled.FormStickyBox>
              <Form
                id="investors"
                inputs={[
                  { placeholder: 'Name*', name: 'firstname' },
                  { placeholder: 'VC/Company Name*', name: 'company' },
                  {
                    placeholder: 'Work email*',
                    type: 'email',
                    name: 'email',
                  },
                  { placeholder: 'Job Title*', name: 'jobtitle' },
                ]}
                formData={{
                  data: formData.data,
                  handleSubmit: formData.handleSubmit,
                  isLoading: formData.isLoading,
                }}
                textareaText="Tell us about your VC / Company"
                buttonText="Let's Talk"
              />
            </styled.FormStickyBox>
          </styled.FormContainer>
        </styled.MainContent>
        {isForm && (
          <styled.CloseBox onClick={() => setState(0)}>
            <styled.CloseIcon src={close} alt="close" />
          </styled.CloseBox>
        )}
      </BlurBox>
    </styled.Box>
  );
};
