import styled from 'styled-components';

import * as text from '@/components/general/TextStyles';
import { StyledButton, StyledLink } from '@/components/general';

interface IBox {
  isForm?: boolean;
}

export const Box = styled.div<IBox>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-top: ${(props) =>
    props.isForm ? '0' : props.theme.headerSize.heightDesktop}px;
  padding: ${(props: IBox) => (props.isForm ? '0' : '0 28px')};

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    margin-top: ${(props) =>
      props.isForm ? '0' : props.theme.headerSize.heightMobile}px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    padding: ${(props: IBox) => (props.isForm ? '0' : '0 20px')};
  }
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h1`
  ${text.FS64}
  margin-bottom: 20px;
  text-align: center;
  max-width: 650px;
`;

export const Description = styled.p`
  ${text.FS22}
  text-align: center;
  max-width: 670px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    font-size: 16px;
  }
`;

export const MainContent = styled.div`
  margin: 100px auto 0 auto;
  display: flex;
  width: 100%;
  max-width: 1100px;
  column-gap: 162px;

  @media (max-width: ${(props) => props.theme.breakpoints.xl}px) {
    column-gap: 20px;
    justify-content: space-between;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    max-width: 400px;
  }
`;

export const VideoFaqBox = styled.div`
  width: 430px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    width: 100%;
  }
`;

export const VideoBox = styled.div`
  width: 100%;
`;

export const VideoTitle = styled.h3`
  ${text.FS24}
  margin-bottom: 20px;
`;

export const Iframe = styled.iframe`
  width: 100%;
  aspect-ratio: 1.76/1;
  border: 1px solid ${(props) => props.theme.colors.borderGray};
  border-radius: 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    aspect-ratio: 1.77/1;
  }
`;

interface IFormContainer {
  $maxHeight: number;
}

export const FormContainer = styled.div<IFormContainer>`
  width: 400px;
  margin-top: 20px;
  max-height: ${(props: IFormContainer) => `${props.$maxHeight - 40}px`};

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    max-height: none;
    margin-top: 40px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    width: 100%;
  }
`;

export const FormStickyBox = styled.div`
  position: sticky;
  top: ${(props) => props.theme.headerSize.heightDesktop + 15}px;
  left: 0;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    position: static;
  }
`;

export const FormBox = styled.form``;

export const FaqBox = styled.div`
  margin-top: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    margin-top: 50px;
  }
`;

export const FaqTitle = styled.h3`
  ${text.FS24}
  margin-bottom: 20px;
`;

export const FaqList = styled.ul`
  width: 100%;

  & > li {
    border-radius: 16px;
  }
`;

export const TextArea = styled.textarea`
  border: ${(props) => props.theme.borderWidth.sm}px solid
    ${(props) => props.theme.colors.black};
  border-radius: ${(props) => props.theme.borderRadius.xss}px;
  width: 100%;
  height: 165px;
  padding: 15px;
  font-weight: ${(props) => props.theme.weights.regular};

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    height: 100px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    padding: 12px;
  }
`;

export const ButtonBox = styled.div`
  margin-top: ${(props) => props.theme.spacing.m3}px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    display: flex;
    justify-content: center;
    margin-top: ${(props) => props.theme.spacing.m1}px;
  }
`;

export const Button = styled(StyledButton)`
  width: 200px;
  height: 50px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    width: 220px;
  }
`;

export const ButtonLink = styled(StyledLink)``;

export const CloseBox = styled.div`
  position: absolute;
  top: 5px;
  right: 0px;
  cursor: pointer;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    top: 0px;
  }
`;

export const CloseIcon = styled.img`
  position: fixed;
  margin-left: -40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.7);

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    margin-left: -25px;
  }
`;
