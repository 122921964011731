import React from 'react';
import { graphql } from 'gatsby';

import { GlobalStyle } from '@/themes/globalStyle';
import { ThemeProvider } from '@/themes/theme';
import Wrapper from '@/components/Wrapper';
import { Header } from '@/components/Header';
import { Content } from '@/components/Investors';
import { Footer } from '@/components/Footer';
import { Links } from '@/utils/constants';
import { Meta } from '@/components/general/Meta';

const WebSummit = ({ data }) => (
  <ThemeProvider>
    <Meta
      title="Invesotrs | CodePlatform"
      description="Let's respect everyone's time. We've prepared the Product Demo, FAQ, and Data Room for you. Feel free to talk with us to get access!"
      url={Links.INVESTORS}
      robots="noindex"
    />
    <GlobalStyle />
    <Wrapper $overflowNotHidden>
      <Header currentpath={Links.INVESTORS} />
      <Content
        data={data.allContentfulInvestorsPage.edges[0].node}
        isForm={false}
      />
      <Footer />
    </Wrapper>
  </ThemeProvider>
);

export default WebSummit;

export const query = graphql`
  query InvestorsQuery {
    allContentfulInvestorsPage {
      edges {
        node {
          faq {
            id
            answer {
              answer
            }
            question
          }
        }
      }
    }
  }
`;
